<template>
  <div class="page_body">
    <div class="page_box">
      <div class="header">
        <img class="header-logo" src="../../assets/image/wjlogo.png" />
        旺家·东泰共建数字社区
      </div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 内页标题 -->
      <div class="page-title">爱心包裹发放名册</div>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <div class="page_conter">
        <div class="select_box">
          <el-select
            style="width: 16vw"
            :popper-append-to-body="false"
            v-model="query.branchId"
            placeholder="请选择认领支部"
            @change="changeSelect"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <div @click="onQuery" class="jump_btn">
            <span class="jump_text">跳转</span>
          </div> -->
          <div v-if="isEditPass" @click="editPage" class="edif_btn">
            编辑信息
          </div>
        </div>
        <div class="list_tips_box">
          <div class="tips_text">
            <span class="tips_label">认领党支部：</span>
            <span class="tips_value">{{ branchName }}</span>
            <span class="tips_num">共认领{{ total }}个爱心包裹</span>
          </div>
        </div>
        <div v-loading="loading" class="table_box">
          <div class="table_content">
            <div class="table_title">
              <span v-for="(item, index) in tableTitleArr" ::key="index">{{
                item
              }}</span>
            </div>
            <div v-if="tableData.length > 0" class="table_conter">
              <div
                v-for="(item, index) in tableData"
                :key="index"
                class="conter_box"
              >
                <!-- 序号 -->
                <span class="span_item">{{ item.id }}</span>
                <!-- 姓名 -->
                <span class="span_item">{{ item.applicant }}</span>
                <!-- 身份证 -->
                <span class="span_item">{{ item.encryptionCardId }}</span>
                <!-- 年龄 -->
                <span class="span_item">{{ item.age }}</span>
                <!-- 电话 -->
                <span class="span_item">{{ item.encryptionPhone }}</span>
                <!-- 住址 -->
                <span class="span_item">{{ item.addr }}</span>
                <!-- 是否贫困户 -->
                <span class="span_item">{{
                  item.poorFlag == "1" ? "是" : "否"
                }}</span>
                <!-- 发放情况 -->
                <span class="span_item">{{ item.wishSituation }}</span>
              </div>
            </div>
            <div class="table_conter conter_no_data" v-else>暂无数据</div>
          </div>
          <div class="pagination_box">
            <el-pagination
              :page-size="query.pageSize"
              :current-page="query.pageNum"
              background
              layout="prev, pager, next"
              :total="total"
              @current-change="changeNum"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Date from "@/views/commonComponents/date.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";
import { formatDate } from "@/utils/date";
import {
  getPartyBranchList,
  getMicroWishList,
  editMicroWish,
} from "@/api/microWish";
export default {
  components: {
    Date,
    rightNav,
  },
  data() {
    return {
      isEditPass: false,
      loading: false,
      dialog: false,
      query: {
        pageNum: 1,
        pageSize: 10,
        branchId: "",
      },
      total: 0,
      year: "",
      month: "",
      day: "",
      time: "",
      week: "",
      weeks: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      options: [],
      tableTitleArr: [
        "序号",
        "姓名",
        "身份证",
        "年龄",
        "电话",
        "住址",
        "是否贫困户",
        "发放情况",
      ],
      tableData: [],
      rowData: {
        id: "",
        branchId: "",
        completeDate: "",
        phone: "",
        remark: "",
        wishDesc: "",
        wishSituation: "",
        wishType: "",
        applicant: "",
      },
      branchName: "",
      isPoorHouseholdsArr: [
        { value: "1", label: "是" },
        { value: "0", label: "否" },
      ],
      wishSituationArr: [
        { wishSituation: "已发放" },
        { wishSituation: "未发放" },
      ],
    };
  },
  watch: {},
  async created() {
    const pass = this.$cookies.get("pass");
    this.isEditPass = pass == "wangjia456" ? true : false;
    await this.getPartyBranchList();
    await this.getList();
    // this.resetDate();
    // setInterval(this.resetDate, 1000);
  },
  async mounted() {},
  methods: {
    editPage() {
      sessionStorage.setItem("branchId", this.query.branchId);
      this.$router.push({
        path: "/editMicroWish",
        query: { branchId: this.query.branchId, branchName: this.branchName },
      });
    },

    changeSelect(val) {
      this.onQuery();
    },
    onQuery() {
      this.query.pageNum = 1;
      this.getList();
    },
    editRowData(row) {
      const pass = this.$cookies.get("pass");
      if (pass == "wangjia456") {
        this.dialog = true;
        this.rowData = row;
        // this.rowData.id = row.id;
        // this.rowData.branchId = row.branchId;
        // this.rowData.completeDate = row.completeDate;
        // this.rowData.phone = row.phone;
        // this.rowData.remark = row.remark;
        // this.rowData.wishDesc = row.wishDesc;
        // this.rowData.wishSituation = row.wishSituation;
        // this.rowData.wishType = row.wishType;
        // this.rowData.applicant = row.applicant;
      }
    },
    async getPartyBranchList() {
      let { rows } = await getPartyBranchList();
      console.log("获取党支部列表", rows);
      this.options = rows;
      this.query.branchId =
        (sessionStorage.getItem("branchId")
          ? sessionStorage.getItem("branchId")
          : rows[0].id) * 1;
      sessionStorage.removeItem("branchId");
      rows.forEach((item) => {
        if (this.query.branchId == item.id) {
          this.branchName = item.name;
        }
      });
    },
    async getList() {
      this.loading = true;
      let { rows, total } = await getMicroWishList(this.query);
      rows.map((item) => {
        item.encryptionPhone =
          item.phone.substr(0, 2) + "*******" + item.phone.substr(9);
        item.encryptionCardId =
          item.cardId.substr(0, 2) + "**************" + item.cardId.substr(16);
      });
      this.loading = false;
      this.tableData = rows;
      this.total = total;
      console.log("获取微心愿列表", rows);
      this.options.forEach((item) => {
        if (item.id == this.query.branchId) {
          this.branchName = item.name;
        }
      });
    },
    changeNum(val) {
      this.query.pageNum = val;
      this.getList();
    },
    goBack() {
      this.$router.push({ path: "/" });
    },
    logOut() {
      this.$cookies.remove("pass");
      this.$router.go(-1);
    },
    onF5() {
      this.$router.go(0);
    },
  },
};
</script>
<style lang='scss' scoped>
@import "./index.scss";
</style>